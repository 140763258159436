/* Count ul li */

.header-main {
  background-color: transparent;
}

.my_menu {
  display: block !important;
}

.sustain_banner {
  width: 100%;
  background-position: center;
  background-size: cover;
}
.nav-pills {
  font-size: 14px !important;
  font-weight: 400;
}
.card_text_center {
  margin: 0px auto !important;
}

.big_font {
  font-size: 30px;
}

.open-offcanvas {
  cursor: pointer;
}
.close-offcanvas {
  cursor: pointer;
}

.para {
  font-size: 12px;
  font-family: "Merriweather Sans", sans-serif;
  font-weight: 400;
  line-height: 1.714;
}
.para_box {
  font-size: 16px;
  font-family: "Merriweather Sans", sans-serif;
  font-weight: 400;
  line-height: 1.714;
  text-transform: capitalize;
}
.slider_text {
  font-size: 16px;
  color: #000;
  font-weight: 400;
  font-family: "Merriweather Sans", sans-serif;
}
.box_price {
  color: #f15f2a;
  font-size: 18px;
  line-height: 1;
  font-weight: 700;
}
.box_list {
  font-size: 18px;
  line-height: 24px;
  display: block;
  text-transform: capitalize;
  color: #29272e;
  margin-bottom: 5px;
}

.blog-item .blog-date {
  left: 90%;
  background: #000;
  font-size: 18px;
  padding: 27px 0;
}
.blog-item .blog-title a {
  color: #98d4cd;
}
.author {
  font-family: "Merriweather Sans", sans-serif;
  font-weight: 400;
  line-height: 1.714;
  color: #000;
  font-size: 14px;
}
.product-info .author .text {
  color: #f15f2a;
}
.blog-item .blog-title a {
  color: #000;
}
.banner_img {
  max-height: 382px !important;
}
.swin-transition:hover {
  text-decoration: none;
}
.contain_space {
  padding-left: 20px;
  padding-right: 20px;
}
.fa {
  color: #f4511e;
}
.heading {
  color: #f4511e;
  font-weight: bold;
}

.list-unstyled {
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  color: #000;
  padding: 12px;
  padding-left: 12px !important;
}

.big_heading {
  font-size: 27px;
  color: #f4511e;
  font-weight: bold;
}
.short-heading {
  font-size: 18px;
  color: #000;
  font-family: "Merriweather Sans", sans-serif;
  line-height: 1.714;
  font-weight: bold;
}

.MuiSvgIcon-root {
  width: 2em !important;
  height: 2em !important;
  font-size: 15px !important;
}
.mydiv {
  background-color: rgba(235, 228, 228, 0.158);
  margin: 0;
  padding: 0;
}
.swin-sc-title .top-title {
  color: #000;
}

.my_big_head2 {
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  color: #f4511e;
}
.myimg {
  text-align: center;
}
.myimg:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

@media screen and (min-width: 992px) {
  .short-banner {
    padding-left: 70px;
    padding-right: 70px;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
  }
  .para {
    font-size: 14px;
    font-family: "Merriweather Sans", sans-serif;
    font-weight: 400;
    line-height: 1.714;
    justify-content: center;
  }
  .footer-para {
    font-size: 15px;
    font-family: "Merriweather Sans", sans-serif;
    font-weight: 400;
    line-height: 1.714;
    justify-content: center;
  }
  .top_heading {
    font-size: 25px;
  }
}

.allergies_img {
  height: 400px !important;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 1100px !important;
    margin: 1.75rem auto;
  }
  .box_img {
    min-height: 50px;
    height: 50px;
  }
  body {
    background-color: black !important;
    font-family: "Merriweather Sans", sans-serif !important;
  }
}

.card_heading {
  color: #989898 !important;
  font-size: 18px;
  position: relative;
  text-transform: uppercase;
  text-align: center;
  line-height: 1;
  border-bottom: 4px solid #de9f43;
  padding-bottom: 4px;
  width: 173px;
}

.card_text {
  font-size: 15px;
  line-height: 18px;
  text-align: left;
  /* color: #948854; */
  color: #000;
  font-weight: bold;
  margin-bottom: 0px;
  margin-left: 5px;
}
.weight_text {
  color: #000000;
  font-weight: 400;
  font-size: 10px;
  margin-left: 10px;
}
.card_desc {
  color: #808080;
  font-size: 11px;
  font-weight: 400;
  text-align: left;
  margin-left: 27px;
  margin-top: 5px;
}
.indicator1 {
  margin-left: 5px;
}
.read_more_btn {
  color: #de9f43;
  font-weight: 500;
  font-size: 11px;
}

.nav-link {
  font-size: 14px;
  color: #000 !important;
}

#main-nav > li > a:after {
  content: "";
  position: absolute;
  height: 2px;
  left: 20px;
  width: 0;
  margin-top: 20px;
  background-color: #f15f2a;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s eas;
}

.nav > li > a {
  position: relative;
  display: block;
  padding: 10px 10px !important;
}

@media only screen and (min-width: 1224px) {
  .nav > li > a {
    position: relative;
    display: block;
    padding: 10px 20px !important;
  }
  .logo-img {
    width: 143px;
    height: 90px !important;
  }
  .margin_top {
    margin-top: 20%;
  }
}
.box_heading {
  font-size: 15px;
  color: #000;
  text-align: center;
  font-weight: bold;
}
.orrange_heading {
  color: #f4511e;
}

.box_img {
  min-height: 100px;
  height: 100px;
}
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #fefdfd !important;
}
.pasta_icon {
  font-size: 40px;
  padding: 20px;
  border: 1px solid;
  transition: all 0.3s ease;
  border-radius: 50%;
  color: #f15f2a;
}
@media only screen and (max-width: 600px) {
  .pasta_icon {
    font-size: 20px;
    padding: 5px;
    border: 1px solid;
    transition: all 0.3s ease;
    border-radius: 50%;
    color: #f15f2a;
  }
  .box_heading {
    font-size: 11px;
    color: #000;
    text-align: center;
    font-weight: bold;
  }

  .counter_logo {
    width: 110px !important;
    left: 60% !important;
    top: 6% !important;
    max-height: 100px !important;
  }
  .counter_indi {
    width: 100% !important;
    position: relative !important;
    left: 0 !important;
    top: 0 !important;
    margin: 10px 20px 150px 20px !important;
  }
  .carousel-height {
    height: 40vh !important;
  }
  #main-nav > li > a:after {
    content: "";
    position: absolute;
    height: 2px;
    left: 20px !important;
    width: 0;
    margin-top: 18px !important;
    background-color: #f15f2a;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s eas;
  }
}

@media only screen and (max-width: 1024px) {
  .footer_logo {
    height: 80px !important;
    padding-top: 5px !important;
  }
}
@media only screen and (max-width: 768px) and (min-width: 700px) {
  .footer_content > p {
    width: 230px !important;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
}

.view-more-button {
  cursor: pointer !important;
  font-family: "Merriweather Sans", sans-serif !important;
  width: 80px !important;
  height: 80px !important;
  font-size: 13px !important;
}

.slider_content {
  font-family: Century Gothic, sans-serif !important;
  font-weight: bold !important;
  background-color: #c0c0c0 !important;
}

.recipe-heading {
  font-size: 24px !important;
}
.counter_logo {
  width: 300px;
  height: 300px !important;
  left: 30%;
  border-radius: 3px;
  top: 30%;
}
.counter_indi {
  position: absolute;
  width: 37%;
  height: 300px !important;
  left: 60%;
  margin-right: 20px !important;
  border-radius: 3px;
  top: 30%;
  padding: 10px;
}
.indicator_icon {
  width: 30px;
}
.indi_font {
  font-size: 12px;
  margin-left: 10px;
  text-align: left;
}

.meal_counter_logo {
  width: 178px;
}

.para_space {
  margin-bottom: 0rem !important;
}
.carousel-height {
  height: 85vh;
}

.carousel-caption {
  position: absolute;
  right: 5% !important;
  bottom: 20px;
  left: 5% !important;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.dropdown:hover .dropdown-menu {
  display: block;
}
.dropdown-menu {
  margin-top: 0;
}
.products {
  height: 100%;
}

.card_title {
  font-size: 14px;
  font-weight: 700;
  color: #f4511e;
}
.big_font_coman {
  font-size: 35px;
}
.fixed-top {
  position: fixed;
  top: 0;
  z-index: 1030;
}
.counterlist {
  display: flex;
  justify-content: center;
}
.counterlist > li {
  padding: 2px 30px !important;
}
.col-container {
  /* display: table; */
  width: 100%;
  justify-content: space-between;
}
.card_height {
  display: table-cell;
  margin-bottom: 30px;
}
.card_height_slider {
  min-height: 320px;
}

@media screen and (max-width: 700px) {
  .counterlist > li {
    padding: 10px 0px !important;
  }
}
.box_image {
  /* filter: url(filters.svg#grayscale); Firefox 3.5+ */
  filter: gray; /* IE5+ */
  -webkit-filter: grayscale(1); /* Webkit Nightlies & Chrome Canary */
  -webkit-transition: all 0.8s ease-in-out;
}

.show > img {
  text-decoration: none;
  filter: none !important;
  -webkit-filter: grayscale(0);
  -webkit-transform: scale(1.01);
  color: #f15f2a !important;
}

.header_slider_text_1 {
  text-align: left !important;
}

.header_slider_text_2 {
  text-align: center !important;
}

.header_slider_text_3 {
  text-align: right !important;
}

.header_slider_position_1 {
  top: 10% !important;
}

.header_slider_position_2 {
  top: 40% !important;
}

.header_slider_position_3 {
  top: 80% !important;
}

@media only screen and (min-device-width: 700px) and (max-device-width: 1040px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .logo-img {
    width: 143px;
    height: 58px;
  }
  .nav > li > a {
    position: relative;
    display: block;
    padding: 10px 10px !important;
  }
}

.logo-img {
  width: 143px;
  height: 60px;
}

.footer_para {
  font-size: 16px;
  font-weight: 400;
}
.left-space {
  margin-left: 20px;
}
.text-right {
  text-align: right;
}
hr {
  background-color: #000;
  color: #000;
  opacity: 0.3;
}
.hr_10 {
  height: 10px;
  color: black;
  background-color: #000;
  opacity: 1;
}
.hr_5 {
  height: 5px;
  color: black;
  background-color: #000;
  opacity: 1;
}
.d-flex {
  display: flex;
  justify-content: space-between;
}
.extra_large {
  font-size: 40px;
  font-weight: bold !important;
  font-family: "Arial Black", "Helvetica Bold", sans-serif;
  color: #000;
  text-align: center;
  margin: 0;
}
.medium_heading {
  font-size: 23px;
  font-weight: bold !important;
  font-family: "Arial Black", "Helvetica Bold", sans-serif;
  color: #000;
  margin: 0;
}
.large_heading {
  font-size: 30px;
  font-weight: bold !important;
  font-family: "Arial Black", "Helvetica Bold", sans-serif;
  color: #000;
  margin: 0;
  /* line-height: 40px; */
}
.details {
  font-size: 18px;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 10px;
}
.short_heading {
  font-size: 18px;
  font-weight: bold !important;
  font-family: "Arial Black", "Helvetica Bold", sans-serif;
  color: #000;
  margin: 0;
  line-height: 10px;
}
#nutritionfacts {
  background-color: white;
  border: 1px solid black;
  padding: 6px;
  width: 40%;
  margin: 10px auto;
  height: 100% !important;
  overflow-x: scroll !important;
  margin-bottom: 30px;
}
#nutritionfacts::-webkit-scrollbar {
  display: none;
}

.w-4 {
  font-weight: 400 !important;
}

@media screen and (max-width: 680px) {
  #nutritionfacts {
    background-color: white;
    border: 1px solid black;
    padding: 3px;
    width: 95% !important;
    margin: auto;
    padding: 10px;
  }
  .extra_large {
    font-weight: bold !important;
  }
  .medium_heading {
    font-weight: bold !important;
  }
  .short_heading {
    font-weight: bold !important;
  }
  .large_heading {
    font-weight: bold !important;
  }
}

.custom-nav-row {
  display: flex;
}

@media (min-width: 1024px) {
  .custom-nav-row {
    display: flex;
    padding: 0 40px;
    justify-content: space-between;
  }
  .custom-nav-row .one {
    width: 30%;
  }
  .custom-nav-row .two {
    width: 70%;
  }
  .custom-nav-row .main-nav ul {
    display: flex;
    justify-content: flex-end !important;
  }
}
@media (max-width: 1400px) and (min-width: 1024px) {
  .custom-nav-row .main-nav a {
    font-size: 12px !important;
  }
}
.footer {
  background-color: rgba(255, 255, 255, 1);
  opacity: 0.8;
  border: 1px dotted gray;
}
.footer .sub {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}
.footer .copyright {
  text-align: center;
  padding: 20px;
}
.footer .copyright > a {
  color: #007bff;
  text-decoration: none;
}
.wrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 15px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.7);
}
.wrap > div > span {
  padding-bottom: 5px;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.7);
}
@media (max-width: 568px) {
  .footer .wrap {
    justify-content: center;
    align-items: center;
  }
}
@media (min-width: 576px) {
  .footer .sub {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 768px) {
  .footer .sub {
    grid-template-columns: repeat(3, 1fr);
  }
}
.closed-counter-text {
  display: flex;
  justify-content: center;
  color: red;
  font-size: 22px;
  margin-top: -40px;
  font-weight: 700;
}
