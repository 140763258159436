

.banner-bg{
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  background-size: contain;
  background-position: center;
}

div.col-md-12.col-12{
  padding: 0 !important;
}